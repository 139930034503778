import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import CallToActionOne from "@/components/call-to-action-one";
import TeamCarousel from "@/components/team-carousel";
import TestimonialsCarousel from "@/components/testimonials-carousel";
import FeatureTabOne from "@/components/feature-tab-one";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import CompanyValues from "@/components/company-values";
import CompanyPolicies from "@/components/company-policies";

const AboutPage = () => {
  const imgSrc= '../../about/about.png'
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="About Us Page">
          <HeaderOne />
          <PageBanner title="About Us" name="About" imgClass='pageBannerAbout'/>
          <FeatureTabOne />
          <hr />
          <CompanyValues />
          <hr />
          <CompanyPolicies />
          {/* <TestimonialsCarousel /> */}
          {/* <TeamCarousel /> */}
          {/* <CallToActionOne extraClassName="ready" /> */}
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default AboutPage;
